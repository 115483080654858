.searchBox {
  display: flex;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 12px;
}

.searchIcon {
  margin-right: 12px;
}

.inputWidth {
  width: 90%;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .searchBox {
    display: flex;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .searchIcon {
    margin-right: 5px;
  }
  .inputWidth {
    font-size: 14px;
  }
}
