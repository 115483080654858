.leaderBoardSection {
  background: white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  padding: 0 1rem;
  margin-bottom: 0.75rem;
}

.searchBox {
  margin: 0 1rem 1rem;
  background: white;
}

.headerContainer {
  padding: 1.5rem 1rem 1.5rem 1rem;
  background: white;
}

.noDataDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5.25rem;
}
.noData {
  font-size: 1rem;
  margin-top: 1.875rem;
  line-height: 19px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .userDetailsCont__LeaderUserInfo {
    display: flex;
    flex-direction: initial;
  }
  .userProfileCont__LeaderUserInfo {
    width: 25%;
  }
  .userNameCont__LeaderUserInfo {
    padding-left: 2px;
    width: 75%;
  }
  .userNameText__LeaderUserInfo {
    font-size: 14px;
    white-space: pre-wrap;
    margin-left: 10px;
  }
  .headerContainer {
    padding: 24px 10px 24px 10px;
  }
  .headerTitle {
    padding: 0px 10px;
  }
  .searchBox {
    margin: 0px 10px 10px 10px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .leaderBoardDataCont__Leaderboard {
    margin: 3.1rem 0rem 0rem 0rem;
    padding: 20px 20px;
    overflow-y: scroll;
    background: #f8f8f8;
    .allUsers_Leaderboard {
      overflow-y: auto;
    }
  }
  .headerTitle {
    font-size: 16px;
  }
  .SearchInp {
    display: flex;
    width: 100%;
    font-size: 14px;
  }
  .headerContainer {
    border-top: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .leaderBoardSection {
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-bottom: 0rem;
    padding-top: 0.8rem;
  }
}
