.name {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 5px;
}

.notSetYet {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.enabledDescription {
  font-size: 0.75rem;
  line-height: 14px;
  color: #000000;
  margin-right: 1rem;
}

.creditCardImage {
  width: 3rem;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1.5rem;
}

.creditCardContent {
  display: flex;
  padding: 1rem 1.875rem;
  justify-content: space-between;
  align-items: flex-start;
}

.viewDetail {
  display: flex;
  align-items: flex-start;
}

.switchDiv {
  display: flex;
  align-items: center;
}

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.cursorPointer {
  cursor: pointer;
}

.chipRoot {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}

.chip {
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 8px;
  background-color: #f5f5f5;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}
.mobileDescription {
  display: none;
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .configureChennalCon__Settings,
  .administratorCont__Settings,
  .firstDivider__Settings,
  .thirdDivider__Settings,
  .description {
    // display: none;
  }
  .creditCardContent {
    padding: 28px 15px 20px 25px;
  }
  .configureCoreCon__Settings,
  .viewDetail,
  .p5BudgetCont__Settings,
  .marketPlaceCont__Settings {
    display: flex;
    align-items: center;
  }
  .name {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 900;
  }
  .creditCardImage {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  .notSetYet {
    display: flex;
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 400;
  }
  .mobileDescription {
   display: none;
  }
}

.lightDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}