.mainContainer {
  padding: 2% 7%;
  background: #f8f8f8;
  height: 100vh;
  overflow-y: auto;
  margin-bottom: 38px;
}
.gridContainer {
  padding-top: 20px;
  padding-bottom: 38px;
}
.heading {
  font-size: 18px;
  font-weight: 900;
}
.menuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filterContainer {
  display: flex;
}
.coreValueFilter {
  padding-right: 12px;
}
.subGrid {
  margin-left: auto;
  height: fit-content;
}
.overview {
  padding-top: 19px;
  padding-bottom: 50px;
}
.overviewText {
  font-weight: 900;
  font-size: 16px;
}
.overviewText1 {
  font-weight: 900;
  font-size: 24px;
  color: #d6a200;
}
.overviewText2 {
  font-weight: 900;
  font-size: 24px;
  color: #4966ff;
}
.overviewText3 {
  font-weight: 900;
  font-size: 24px;
  color: #24b88b;
}
.overviewText4 {
  font-weight: 900;
  font-size: 24px;
  color: #ff7269;
}

.settingsBtn__AnalyticDashboard,
.closeBtn__AnalyticDashboard {
  display: none;
}

@media only screen and (min-width: 1877px) {
  .mainContainer {
    padding: 2% 5%;
    max-width: 1400px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1877px) {
  .mainContainer {
    padding: 2% 6%;
    max-width: 1400px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .mainContainer {
    padding: 2% 6%;
  }
  .overviewText {
    font-size: 14px;
  }
  .overviewText1,
  .overviewText2,
  .overviewText3,
  .overviewText4 {
    font-size: 20px;
  }
  .subGrid {
    margin: 0px 0px 16px 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .mainContainer {
    padding: 31px;
  }
  .subGrid {
    margin: 0px 0px 16px 16px;
    min-width: 47%;
  }
}

@media only screen and (min-width: 600px) {
  .overview {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .mainContainer {
    padding: 20px 25px;
  }
  .gridContainer {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .overview {
    padding-top: 0px;
    padding-bottom: 17px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
  .overviewText {
    font-weight: 900;
    font-size: 14px;
    line-height: 16.8px;
  }

  .overviewText1,
  .overviewText2,
  .overviewText3,
  .overviewText4 {
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .subGrid {
    margin: 16px 0px 16px 0px;
    padding: 0px;
  }
  .filterContainer {
    display: none;
  }
  .settingsBtn__AnalyticDashboard {
    display: flex;
    min-width: 0px;
    border-radius: 50%;
    background-color: #e4e4e4;
    padding: 10px;
    width: 40px;
    height: 40px;
  }
  .closeBtn__AnalyticDashboard {
    display: flex;
    min-width: 0px;
    border-radius: 50%;
    background-color: #e4e4e4;
    padding: 10px;
    width: 40px;
    height: 40px;
  }
}
