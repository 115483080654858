.StripeForm {
  display: contents;
  color: #a0a0a0;
}
.BillingForm {
  display: contents;
  color: #a0a0a0;
}
.StripeFormFields {
  display: flex;
}
.StripeFormFields label {
  width: 273px;
}
.selectCountry {
  width: 100%;
  height: 43px;
  color: #a0a0a0;
  font-size: 16px;
}
.StripeForm label {
  font-weight: 500;
  font-size: 12px;
  border: 1px #d7d7d7;
  margin-bottom: 0px;
}
.BillingForm label {
  font-weight: 500;
  font-size: 12px;
  border: 1px #d7d7d7;
  margin-bottom: 20px;
}
.InlineLabel {
  display: grid;
}
.formControlSelect {
  margin-right: 32px;
  display: contents;
}

.StripeElement {
  display: block;
  max-width: 35.625rem;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background: white;
  margin: 0 0 20px 0;
}
input::placeholder {
  color: #a0a0a0;
}
.StripeFormFields input,
.InlineLabel input {
  width: 100%;
  display: block;
  max-width: 35.625rem;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background: white;
  height: 43px;
}

// .StripeFormFields input:focus,
// .StripeElement--focus {
//   box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
//     rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
//   -webkit-transition: all 150ms ease;
//   transition: all 150ms ease;
// }

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.errorMessage {
  justify-content: center;
  display: flex;
  color: #e93a3a;
  font-size: 12px;
}

.suggestionText {
  font-size: 16px;
  margin-bottom: 10px;
  color: #a0a0a0;
}
.consentText {
  font-size: 16px;
  color: #5a5a5a;
  line-height: 19px;
}
.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.CardDetailsTitle {
  font-weight: 900;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}

.teamSize {
  font-size: 16px;
  color: #a0a0a0;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;
}
.loader {
  margin: unset;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 24px;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CardDetailsTitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .suggestionText {
    text-align: center;
  }
  .consentText {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
