.headerContainer {
  display: flex;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  position: relative;
  //styleName: P5-Header-H2-24-px;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.bodyContainer {
  display: flex;
  padding: 1.5rem;
  position: relative;
}

.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.footer {
  margin: 0 1rem 0.5rem 0;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
