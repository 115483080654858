.card {
  display: flex;
  padding: 1rem 1.875rem;
}

.logo {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  margin-right: 1.5rem;
  border-radius: 4px;
}

.name {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 5px;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.viewPlan {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  margin-top: 1rem;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 0.75rem 1.25rem;
  text-transform: capitalize;
}

.creditCardImage {
  width: 3rem;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1.5rem;
}

.lightDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}

.normal {
  font-weight: normal;
}

.creditCardContent {
  display: flex;
  padding: 1rem 1.875rem;
  justify-content: space-between;
}

.viewDetail {
  display: flex;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
  position: relative;
}

.infoBox {
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  line-height: 19px;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  width: fit-content;
}

.bold {
  font-weight: 900;
}

.warning {
  background: #fffadf;
  color: #d6a200;
}

.alert {
  background: #fff4f4;
  color: #d60000;
}
.canceled {
  background: rgba(0, 0, 0, 0.12);
  color: #000;
}
.billingIcon {
  margin-right: 21px;
}
.topScrollPaper {
  align-items: center;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .billingIcon,
  .logo,
  .creditCardImage {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  .name,
  .description {
    font-size: 16px;
    font-weight: 900;
  }

  .creditCardContent,
  .card {
    padding: 28px 15px 20px 25px;
  }

  .infoBox,
  .lightDescription {
    font-size: 14px;
  }

  .dialogBox__CurrentSubscription {
    height: 100vh;
  }
  .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-end;
  }
  .topScrollPaper {
    align-items: flex-end;
  }
  .creditCardImage {
    object-fit: contain;
  }
}
