.outerWrapper {
  margin: 0 auto;
  min-width: 35.625rem;
  display: flex;
  flex-direction: column;
}

.CardDetailsTitle {
  font-weight: 900;
  font-size: 18px;
  color: #000000;
}

.detailsTitle {
  @extend .CardDetailsTitle;
  margin: 28px 0;
}

.formItemLabel {
  font-size: 16px;
  margin-bottom: 10px;
  color: #a0a0a0;
}

.onBoardingText {
  margin-bottom: 30px;
}

.defaultSelection {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 10px 12px;
  :hover {
    cursor: pointer;
  }
}

.calculationTip {
  font-size: 12px;
  line-height: 14px;
  color: #5a5a5a;
  margin-top: 6px;
}

.otherOptions {
  margin-top: 30px;
}

.otherSelection {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  // width: 100%;
  position: relative;
}

.optionsArrow {
  position: absolute;
  top: 15px;
  right: 2%;
  line-height: 0;
  padding: 4px 6px;
}

.optionContainer {
  padding: 10px 12px;
  :hover {
    cursor: pointer;
  }
}

.customPointContainer {
  margin-top: 30px;
}

.yellowBackground {
  background-color: #fffadf;
}

.optionTitle {
  display: flex;
  gap: 15px;
}

.optionDescription {
  color: #5a5a5a;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.boxTitle {
  font-size: 1rem;
  line-height: 19px;
  color: #5a5a5a;
  padding-top: 6px;
}

.greyText {
  color: #a0a0a0;
}

.boxBudgetTitle {
  color: #a0a0a0;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
}

.inputBox {
  width: 50%;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  height: 2.8rem;
  padding-left: 12px;
  padding-right: 12px;
}

.customPointsPostLabel {
  padding-left: 12px;
}

.boxInputContainer {
  margin: 1.875rem 0;
}

.footer {
  // margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.textWarning {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding-top: 10px;
  color: #a0a0a0;
}

.budgetFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
}

.needAssistance {
  margin: auto 31px;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  cursor: pointer;
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .innerWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .outerWrapper {
    align-items: center;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .outerWrapper {
    min-width: 90vw;
    padding: 1rem 1.156rem;
  }

  .title {
    font-size: 16px;
    margin: 36px 0px 11px 0px;
  }

  .titleDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 2px;
  }
  .boxTitle {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
  }
  .boxBudgetTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
  }
  .boxInputContainer {
    width: 100%;
    margin-bottom: 0px;
  }
  .budgetFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .needAssistance {
    text-align: center;
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 18px;
  }

  .onBoardingText,
  .boxTitle,
  .CardDetailsTitle,
  .detailsTitle,
  .formItemLabel {
    text-align: center;
  }
}
