.headerContainer {
  display: flex;
  padding: 1rem 1.5rem;
  position: relative;
}
.bodyContainer {
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  position: relative;
}
.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
}
.headerTitleContainer {
  display: flex;
  align-items: center;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.gridContainer {
  padding: 1.5rem;
}

.borderRight {
  border-right: 1px solid #dfdfdf;
}

.boxContainer {
  display: flex;
  padding: 1rem;
  background: #fffadf;
  margin-top: 0.75rem;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
}

.lightGreenBox {
  background: #dbf3eb;
}

.borderBox {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.boxBudgetTitle {
  color: #a0a0a0;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
}

.boxInputContainer {
  margin-bottom: 1.5rem;
}

.budgetButton {
  background-color: black;
  color: white;
  text-transform: none;
  margin-left: 5px;
  margin-bottom: 1.5rem;
}

.budgetButton:hover {
}

.boxTitle {
  font-size: 1rem;
  line-height: 19px;
}

.boxAmount {
  font-weight: 900;
}

.innerSquare {
  width: 12px;
  height: 12px;
  background: #000000;
  margin: 2px;
}

.outerSquare {
  border: 1px solid #000000;
}

.suggestionText {
  font-size: 12px;
  margin-bottom: 10px;
  color: #a0a0a0;
}
.consentText {
  font-size: 16px;
}
.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}
.CardDetailsTitle {
  font-weight: 900;
  font-size: 18px;
  padding: 20px 0px 10px 0px;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;
}
.loader {
  margin: unset;
}
.BillingForm label {
  font-weight: 500;
  font-size: 12px;
  border: 1px #d7d7d7;
  margin-bottom: 20px;
}
.InlineLabel {
  display: grid;
}
.BillingForm {
  display: contents;
  color: #a0a0a0;
}
.loader {
  margin: unset;
}
