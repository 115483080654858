/* make the customizations */
// $theme-colors: (
//         "info": tomato,
//         "danger": teal
// );

$card-border-radius: 0px;
$card-bg: #f00;
@import "../node_modules/bootstrap/scss/bootstrap";
@import "./variable.scss";

/* import bootstrap to set changes */
// @import url("https://d1zq6pyjrqicyj.cloudfront.net/bnNJetTfdHsQ/wWpIOvSBSEwS/bootstrap.min.css")

html * {
  font-family: $font-family-sans-serif !important;
}
body {
  #settingsTooltip,
  #subscriptionTooltip {
    z-index: 0;
  }

  #menuTooltip {
    z-index: 1101;
  }

  padding: 0;
  overflow-y: hidden;
  button:focus {
    outline: none !important;
  }
  background-color: $primary;
  .header {
    background-color: white !important;
    border-bottom: 1px solid #ececec;
    box-shadow: none;
    color: black !important;
  }
  .peerfive-logo {
    width: 150px;
  }
  .MuiTab-textColorPrimary {
    color: black !important;
  }
  .MuiTab-root {
    height: 56px;
    font-size: 16px !important;
  }
  .PrivateTabIndicator-colorPrimary-9 {
    background-color: black !important;
  }
  .MuiTab-wrapper {
    // font-family: "Lato", "Roboto", "Sans Sherif" !important;
    text-transform: capitalize;
  }
  .MuiListItem-button:hover {
    background-color: #fffadf !important;
  }
  .leaderboard-section {
    background-color: white;
  }
  .divider {
    border-top: 3px solid #bbb;
  }
  .redeem-btn {
    &:hover {
      background-color: black;
      color: white;
    }
    background-color: black;
    color: white;
  }
  .leftPanel {
    background-color: #f8f8f8;
  }
  .emoji-div {
    display: flex;
  }
  .emoji-mart-emoji {
    position: relative;
    top: 2px;
  }
}

.loader {
  margin: 10px auto;
  font-size: 4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #3c2e3d,
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.5),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.7),
      1.8em -1.8em 0 0em #3c2e3d, 2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.5),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.7), 2.5em 0em 0 0em #3c2e3d,
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.5),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.7), 1.75em 1.75em 0 0em #3c2e3d,
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.5),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.7), 0em 2.5em 0 0em #3c2e3d,
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.5),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.7), -1.8em 1.8em 0 0em #3c2e3d,
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.5),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.7), -2.6em 0em 0 0em #3c2e3d,
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.5),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.7), -1.8em -1.8em 0 0em #3c2e3d;
  }
}

@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #3c2e3d,
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.5),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.7),
      1.8em -1.8em 0 0em #3c2e3d, 2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.5),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.7), 2.5em 0em 0 0em #3c2e3d,
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.5),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.7), 1.75em 1.75em 0 0em #3c2e3d,
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.5),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.7), 0em 2.5em 0 0em #3c2e3d,
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.2),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.5),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.7), -1.8em 1.8em 0 0em #3c2e3d,
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.5),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.7), -2.6em 0em 0 0em #3c2e3d,
      -1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(60, 46, 61, 0.2),
      1.8em -1.8em 0 0em rgba(60, 46, 61, 0.2),
      2.5em 0em 0 0em rgba(60, 46, 61, 0.2),
      1.75em 1.75em 0 0em rgba(60, 46, 61, 0.2),
      0em 2.5em 0 0em rgba(60, 46, 61, 0.2),
      -1.8em 1.8em 0 0em rgba(60, 46, 61, 0.5),
      -2.6em 0em 0 0em rgba(60, 46, 61, 0.7), -1.8em -1.8em 0 0em #3c2e3d;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .App {
    background-color: #f8f8f8;
    height: 100vh;
  }
  .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-end;
  }
}

@media only screen and (min-width: "1400px") {
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f8f8f8;
  }
  .header {
    width: 100vw;
    display: flex;
    align-items: center;
  }
}
