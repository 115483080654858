@import "custom";

.mainContainer {
  width: 100%;
  height: 100vh;
  background: #fffadf;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  overflow: auto;
}

.SaveCard {
  justify-content: center;
}

.CoreValues {
  padding-bottom: 0rem;
}

.cardContainer {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  max-width: 35.438rem;
  margin: 2.5rem 0;
  padding: 3.5rem 7.688rem;
  display: table;
}

.title {
  font-weight: 900;
  font-size: 2rem;
  line-height: 38px;
  color: #000000;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.slackButton {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin-top: 2rem;
  padding: 0.875rem;
}

.slackLoading {
  padding-top: 1.875rem;
  text-align: center;
}

.slackButton a {
  color: #000000 !important;
  text-decoration: none !important;
  text-transform: initial;
  font-weight: 400;
  font-size: 1rem;
}

.slackImage {
  height: 20px;
  width: 20px;
  margin-right: 0.75rem;
}

.slackBold {
  font-weight: 700;
}

.footerTitle {
  line-height: 19px;
  font-size: 1rem;
  color: #a0a0a0;

  .contactus {
    color: #ffc50d;
    cursor: pointer;
  }
}
.saveCardContainer__Onboarding {
  padding: 3.5rem 0.688rem;
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .mainContainer {
    padding: 5rem 2rem;
  }
  .cardContainer {
    padding: 2rem 2rem;
  }
}

@media only screen and (min-width: 0px) and (max-width: 700px) {
  .mainContainer {
    padding: 5rem 0rem;
  }
  .cardContainer {
    padding: 2rem 0rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 700px) {
  .coreValueContainer__Onboarding,
  .budgetContainer__Onboarding {
    min-width: 90vw;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .cardDetailsContainer__Onboarding,
  .budgetContainer__Onboarding {
    width: 90vw;
  }
  .peerFivesLogo {
    width: 189px;
    height: 50px;
  }
  .description {
    font-size: 14px;
  }
  .boxContainer {
    width: 81vw;
  }
  .saveCardContainer__Onboarding {
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}
