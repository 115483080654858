.headerContainer {
  display: flex;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  position: relative;
}
.bodyContainer {
  display: flex;
  padding: 1.5rem;
  position: relative;
}

.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.gridContainer {
  padding: 1.5rem;
}

.borderRight {
  border-right: 1px solid #dfdfdf;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.viewPlan {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  margin-top: 1rem;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 0.75rem 1.25rem;
  text-transform: capitalize;
}

.creditCardImage {
  width: 3rem;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1.5rem;
}

.lightDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}

.creditCardContent {
  display: flex;
  padding: 1rem 0rem;
  justify-content: space-between;
}

.viewDetail {
  display: flex;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
}

.infoBox {
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  line-height: 19px;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  width: fit-content;
}
.footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
/*card details css*/
.StripeFormWrapper {
  margin: 0 auto;
  max-width: 35.625rem;
  display: flex;
  flex-direction: column;
}

.contentBox {
  padding: 20px 0px 30px 0px;
  text-align: center;
}

.gstNumber {
  margin: 0;
  font-size: 10px;
}

.teamSize {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dbf3eb;
  margin-top: 50px;
  height: 68px;
  width: 115px;
  left: 664px;
  top: 175px;
  border-radius: 8px;
  margin: auto;
}
.teamSizeText {
  font-size: 32px;
  font-weight: 900;
}
.onBoardingText {
  margin-top: 10px;
  text-align: center;
}
.avatar {
  margin: 20px 0px 30px 0px;
  justify-content: center;
  display: flex;
}

.boxContainer {
  display: flex;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  margin-top: 0.75rem;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
}
.boxTitle {
  font-size: 1rem;
  line-height: 19px;
}
.boxAmount {
  font-weight: 900;
}
.menuPrice {
  margin-top: 4.5px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 4px;
  border: 1px solid #ececec;
}
.menuItems {
  a:hover {
    background: white;
  }
  background: white;
}
.menuItems .MuiListItem-button:hover {
  background-color: white !important;
}
.boxTitleGrey {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}
.planPrice {
  font-size: 1rem;
  float: left;
  margin-left: auto;
}
.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;

  .loader {
    margin: unset;
  }
}
.dropDownIcon {
  cursor: pointer;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;

  .loader {
    margin: unset;
  }
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

@media only screen and (min-width: 600px) {
  .dropDown__ActiveSubscriptionDialog {
    z-index: 1;
    width: 35.7rem;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .dropDown__ActiveSubscriptionDialog {
    width: 90%;
  }
  .menuItem__ActivitySubscriptionDialog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .planPrice {
    width: 100%;
  }
  .footer {
    flex-direction: column;
  }
  .teamSizeText {
    font-size: 22px;
  }
  .teamSize {
    height: 52px;
    width: 69px;
  }
}
