.StripeElement {
  display: block;
  max-width: 35.625rem;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background: white;
  margin: 0 0 20px 0;
}
input::placeholder {
  color: #a0a0a0;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
