.card {
  min-width: 37rem;
  // transition: height 2s transform 2s;
  // transition: all 2s ease-out;
}

.title {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  margin-top: 1.875rem;
  margin-bottom: 0.5rem;
}

.boxTitle {
  font-size: 1rem;
  line-height: 19px;
}
.placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #a0a0a0;
}
.bottomText {
 font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 2rem;
}
.boxBudgetTitle {
  color: #a0a0a0;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
}

.boxInputContainer {
  margin: 1.875rem 0;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media only screen and (min-width: 0px) and (max-width: 700px) {
  .card {
    min-width: 81vw;
    width: 80%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .placeholder {
    width: 81vw;
    text-align: center;
  }
}
