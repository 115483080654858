.coreValueContainer {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ececec;
  padding: 1.25rem 1.25rem 0.5rem 1.25rem;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  height: 236px;
  align-content: flex-start;
}

.chip {
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 8px;
  background-color: #f5f5f5;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  display: flex;
  align-items: center;
}
.suggestionChip {
  margin-right: 0.75rem;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  border: solid 1px #dcdcdc;
  cursor: pointer;
}
.suggestionChip:hover {
  background-color: #f5f5f5;
}
.suggestionBox {
  display: flex;
  align-items: baseline;
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
  flex-wrap: wrap;
  gap: 10px 0;
}
.suggestionText {
  color: #a0a0a0;
  font-weight: normal;
  font-size: 16px;
  padding: 8px 20px 8px 0px;
}
.placeholder {
  padding: 40px;
  color: #a0a0a0;
}

.chipRoot {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.75rem;
}

.closeIcon {
  height: 16px;
  fill: #a0a0a0;
  margin-left: 5px;
  cursor: pointer;
}

.addInput {
  border: 1px solid black;
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 8px;
  padding: 2px 12px;
}

.addInput:focus {
  outline: none !important;
}

.addIcon {
  margin: 0 0.75rem 0.75rem 0;
  cursor: pointer;
}

.budgetFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 5px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.addMargin{
 margin-top: 2rem;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .suggestionBox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding: 0% 5%;
  }
  .suggestionText {
    width: 100%;
  }
  .suggestionChip {
    margin: 15px 18px 18px 0px;
  }
  .coreValueContainer {
    width: 81vw;
    margin-top: 36px;
    display: none;
  }
  .coreValueContainer {
    border: none;
    border-top: 1px solid #ececec;
    border-radius: 0px;
  }
  .footer {
    margin: 1.875rem;
  }
}
