.CardDetailsTitle {
  font-weight: 900;
  font-size: 18px;
  color: #000000;
}

.onBoardingText {
  margin-bottom: 30px;
}

.sectionContainer {
  border-radius: 4px;
  height: 50px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.yellowBackground {
  background: #fff6db;
}

.greenBackground {
  background: #dbf3eb;
}

.sectionValues {
  font-weight: 900;
}

.information {
  margin-top: -14px;
  font-weight: 500;
  font-size: 12px;
  color: #5a5a5a;
  text-align: right;
}
