@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 2.5s ease;
}

.playerWrapper {
  width: 100%;
  height: 85%;
  margin-right: 1rem;
  div:nth-child(1) {
    width: 100%;
    height: 100%;
  }
}
