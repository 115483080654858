.handPointer {
  cursor: pointer !important;
}

.dialogPaper {
  // position: unset;
  // overflow-y: unset;
  border-radius: 8px;
  :global(.MuiDialogActions-root) {
    justify-content: unset;
  }

  @media screen and (min-width: 0px) and (max-width: 599px) {
    width: 100vw;
    max-height: 100%;
    max-width: 100vw !important;
    margin: 0;
  }
}

.noPadding {
  padding: 0;
}

.dialogHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: start;
}

// .dialogModel__DialogModel {
//   @media screen and (min-width: 0px) and (max-width: 599px) {

//   }
// }
