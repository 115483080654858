.card {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-top: 1.5rem;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #a0a0a0;
  padding: 1rem 1.875rem;
}

.cardContent {
  // padding: 1rem 1.875rem;
}

.header__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.integrationCont__card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.875rem;
}

.integrationText__card {
  margin-left: 10px;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .headerTitle {
    display: none;
  }
  .cardDivider__MainCard {
    display: none;
  }
  .card {
    border: 1px solid #ececec;
    box-shadow: 0px 4px 10px 0px #0000000a;
  }
}
