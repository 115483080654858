@import "../../../../variable.scss";
.StripeFormWrapper {
  margin: 0 auto;
  min-width: 35.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentBox {
  padding: 20px 0px 30px 0px;
  text-align: center;
}

.gstNumber {
  margin: 0;
  font-size: 10px;
}

.celebrateCap {
  padding-left: 8px;
}

.celebrateCap:hover {
  cursor: text !important;
}

.onBoardingText {
  margin: 10px 0 30px;
}
.avatar {
  margin: 0px 0px 33px 0px;
  justify-content: center;
  display: flex;
}

.boxContainer {
  display: flex;
  padding: 1rem;
  background: #fffadf;
  border: none;
  margin-top: 0.75rem;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
}

.logo {
  max-height: 7rem;
  min-height: 3.2rem;
}
.boxTitle {
  font-size: 16px;
  line-height: 22px;
  color: #5a5a5a;
}
.boxAmount {
  font-weight: 900;
}
.menuPrice {
  margin-top: 4.5px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 4px;
  border: 1px solid #ececec;
}
.menuItems {
  a:hover {
    background: white;
  }
  background: white;
}
.menuItems .MuiListItem-button:hover {
  background-color: white !important;
}
.boxTitleGrey {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
}
.planPrice {
  font-size: 1rem;
  float: left;
  margin-left: auto;
}
.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;

  .loader {
    margin: unset;
  }
}
.dropDownIcon {
  cursor: pointer;
}

.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;

  .loader {
    margin: unset;
  }
}

.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.CardDetailsTitle {
  font-weight: 900;
  letter-spacing: 0em;
  font-size: 24px;
  line-height: 29px;
  padding-top: 9px;
  color: #000000;
}
.boxTitle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  padding-top: 6px;
}

.OnBoardingOneForm__dropdownMenuContainer {
  position: absolute;
  width: 567px;
  z-index: 1;
  top: 0px;
  left: 0px;
  transform: translate3d(169px, 375px, 0px);
  will-change: transform;
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
  .OnBoardingOneForm__detailsContainer {
    display: flex;
    flex-direction: column;
    width: 87%;
  }
  .OnBoardingOneForm__dropdownMenuContainer {
    width: 496px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 700px) {
  .OnBoardingOneForm__detailsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
  .OnBoardingOneForm__dropdownMenuContainer {
    width: 456px;
  }

  .logoContainer {
    text-align: center;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .StripeFormWrapper {
    min-width: 90vw;
    padding: 1rem 1.156rem;
  }
  .OnBoardingOneForm__detailsContainer {
    width: 100%;
  }
  .teamSize {
    width: 67px;
    height: 38px;
  }
  .teamSizeText {
    font-family: $font-family-sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .CardDetailsTitle {
    font-family: Lato;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
  .boxTitle {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
  .avatar {
    margin: 14px 0px 22px 0px;
  }

  .boxContainer {
    background: #fffadf;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border: none;
  }
  .boxAmount {
    font-family: Lato;
    font-size: 16px;
    font-weight: 900;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 6px;
  }
  .dropDownIcon {
    position: absolute;
    right: 4%;
    top: 19%;
  }

  .contentBox {
    padding: 22px 0px 30px 0px;
  }
  .details__contentBox {
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
  }
  .details__contentBox span {
    font-weight: 700;
  }

  .boxTitleGrey {
    font-size: 14px;
  }
  .planPrice {
    font-size: 14px;
  }
  .logoContainer {
    text-align: center;
  }
}

@media only screen and (min-width: 474px) and (max-width: 600px) {
  .OnBoardingOneForm__dropdownMenuContainer {
    width: 83vw;
  }
}
@media only screen and (min-width: 0px) and (max-width: 474px) {
  .OnBoardingOneForm__dropdownMenuContainer {
    width: 81vw;
  }
  .menuItems li {
    flex-direction: column;
    align-items: flex-start;
  }
  .menuItems li p {
    white-space: pre-line;
  }
  .planPrice {
    margin: 0px;
  }
}
