$primary: #00d339;
$checkmarkColor: white;
$size: 22px;
$checkmarkFat: 1.5px;

body {
  padding: 20px;
}

.circle {
  border-radius: 50%;
  width: $size;
  height: $size;
  border: 1px #c8c8c8 solid;
}

.circleChecked {
  position: relative;
  border: none;
  background: $primary;
}

.checkmark {
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 33%;
  top: 45%;
  height: $size/2.5;
  width: $size/4.5;
  border-bottom: $checkmarkFat solid $checkmarkColor;
  border-right: $checkmarkFat solid $checkmarkColor;
}
