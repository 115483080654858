.headerContainer {
  display: flex;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  position: relative;
}
.bodyContainer {
  display: flex;
  padding: 1.5rem;
  position: relative;
}

.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.gridContainer {
  padding: 1.5rem;
}

.borderRight {
  border-right: 1px solid #dfdfdf;
}
.StripeFormWrapper {
  margin: 0 auto;
  max-width: 35.625rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .headerContainer {
    padding: 1rem;
  }
}
