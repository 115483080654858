.mainBox {
  display: flex;
  align-items: center;
}

.normalRoundBox {
  width: 32px;
  height: 32px;
  background: #000000;
  font-size: 16px;
  line-height: 19px;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background: #dbf3eb;
  color: #000000;
}

.divider {
  background: #a0a0a0;
  width: 40px;
  height: 1px;
}

.prevButton {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
}

@media only screen and (max-width: 700px) {
  .stepperWrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
