@import "custom";

.mainContainer {
  width: 100%;
  height: 100vh;
  background: #fffadf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  max-width: 48rem;
  margin: 2.5rem 0;
  padding: 3.5rem 7.688rem;
  display: table;
  text-align: center;
}

.welcomeContainer {
  // padding: 3rem;
  border-bottom: 1px solid #ececec;
  text-align: center;
}

.footerContainer {
  padding: 1.25rem 3rem;
}

.title {
  font-weight: 900;
  font-size: 2rem;
  line-height: 38px;
  color: #000000;
  margin-bottom: 1rem;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.slackButton {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  margin-top: 2rem;
  padding: 0.875rem;
  text-transform: none;
}
.msteamsBtn {
  margin-top: 1rem;
}

.slackLoading {
  text-align: center;
}

.slackButton a {
  color: #000000 !important;
  text-decoration: none !important;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1rem;
}

.slackImage {
  height: 20px;
  width: 20px;
  margin-right: 0.75rem;
}

.slackBold {
  padding: 0 3px;
  font-weight: 700;
}

.footerTitle {
  line-height: 19px;
  font-size: 1rem;
  color: #a0a0a0;
  margin-top: 1.875rem;

  .contactus {
    color: #ffc50d;
    cursor: pointer;
  }
}

@media only screen and (min-width: "600px") and (max-width: "900px") {
  .cardContainer {
    padding: 3.5rem 3.688rem;
    max-width: 31rem;
  }
}

@media only screen and (min-width: "0px") and (max-width: "600px") {
  .mainContainer {
    justify-content: flex-start;
    padding-top: 5rem;
  }
  .peerFivesLogo {
    width: 189px;
  }
  .cardContainer {
    padding: 2rem;
    max-width: 21rem;
  }
  .slackImage {
    width: fit-content;
  }
  .title {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
  }
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .footerTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
}
