.headerWrapper {
  display: flex;
  padding: 1.5rem;
  position: relative;
  width: 100%;
  justify-content: space-between;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dfdfdf;
}
.bodyContainer {
  display: flex;
  padding: 1.5rem;
  position: relative;
}

.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 24px;
  line-height: normal;
  color: #000000;
}

.headerSubTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: #a0a0a0;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
}

.gridContainer {
  padding: 1.5rem;
}

.borderRight {
  border-right: 1px solid #dfdfdf;
}
.StripeFormWrapper {
  margin: 0 auto;
  max-width: 35.625rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .headerContainer {
    padding: 1rem 1rem 0rem 1rem;
  }
  .bodyContainer {
    padding: 1rem;
  }
  .StripeFormWrapper {
    max-width: 33rem;
  }
}
