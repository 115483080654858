.card {
  display: flex;
  padding: 1rem 1.875rem;
}

.container {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  margin-right: 1.5rem;
  border-radius: 50%;
}

.designation {
  margin-top: 1.5rem;
}

.name {
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 5px;
  color: #b8b8b8;
}

.description {
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
}

.imageCon__SupportCard {
  margin-right: 19px;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .card {
    padding: 25px;
  }

  .description {
    font-size: 18px;
    font-weight: 900;
  }
  .imageCon__SupportCard {
    margin-right: 0px;
    width: 40px;
    height: 40px;
  }
}
