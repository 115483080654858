.card {
  display: flex;
  padding: 1rem 1.875rem;
}

.logo {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  margin-right: 1.5rem;
  border-radius: 4px;
}

.name {
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 5px;
}

.description {
  font-size: 1rem;
  line-height: 19px;
  color: #b8b8b8;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .card {
    padding: 25px;
    display: flex;
    flex-direction: column;
  }
  .logo {
    width: 60px;
    height: 60px;
    margin-bottom: 22px;
  }
  .name {
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #b8b8b8;
  }
}
