.mainContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
}

.userImage {
  width: 42px;
  height: 42px;
  margin-right: 10px;
  border-radius: 50%;
}

.content {
  display: flex;
  align-items: center;
}

.inputBox {
  width: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.linkButton {
  color: #000000;
  font-size: 14px;
}
.adminText {
  padding-right: 5px;
  font-size: 14px;
}
.inputBorderColor {
  border: 1px solid #ececec;
}

.inputBorderColor input {
  text-align: left !important;
}

.inputBox input {
  padding: 10px;
  text-align: right;
}

.inputBox
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.userName {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
}

.iconBox {
  border-radius: 4px;
  padding: 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
}

.iconImg {
  width: 15px;
}

.iconBoxBlack {
  background: #000000;
}
.skipButton {
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 0.5rem 0.7rem;
  text-transform: capitalize;
}

.saveButton {
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 0.5rem 0.7rem;
  text-transform: capitalize;
}
