.headerContainer {
  display: flex;
  padding: 1rem 1.5rem;
  position: relative;
}

.divider {
  background: #dfdfdf;
  width: calc(100% - 3rem);
  height: 1px;
  margin: 0 auto;
}

.headerTitle {
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
}

.headerDescription {
  font-size: 1rem;
  line-height: 19px;
  color: #a0a0a0;
  margin-top: 5px;
}

.logoIcon {
  width: 50px;
  height: 50px;
  margin-right: 1.25rem;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.gridContainer {
  padding: 1.5rem;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.borderRight {
  border-right: 1px solid #dfdfdf;
}

.boxContainer {
  display: flex;
  padding: 1rem;
  background: #fffadf;
  margin-top: 0.75rem;
  align-items: center;
  border-radius: 4px;
  justify-content: space-between;
}

.lightGreenBox {
  background: #dbf3eb;
}

.exampleStoreDivWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1.5rem;
}

.monthlySpendDivWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1.5rem;
}

.exampleStoreDiv {
  display: flex;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(151, 116, 20, 0.08);
  padding: 12px;
}

.peerFivesCostDiv {
  border-radius: 4px;
  border: 1px solid #ffbf1c;
  background: #fffadf;
  padding: 9px 12px;
  display: flex;
  align-items: center;
}

.peerFivesCostDivWithLogo {
  display: flex;
  gap: 6px;
  margin-top: 1rem;
}
.calculationDiv {
  border-radius: 4px;
  border: 1px solid #ffbe0e;
  background: #fffef5;
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.infoDiv {
  border-radius: 4px;
  background: #fffadf;
  margin-bottom: 1.5rem;
  padding: 12px;
}

.borderBox {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
}

.boxBudgetTitle {
  color: #a0a0a0;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
}

.boxInputContainer {
  margin-bottom: 1.5rem;
}

.budgetButton {
  background-color: black;
  color: white;
  text-transform: none;
  margin-left: 5px;
  margin-bottom: 1.5rem;
}
.budgetLoader {
  margin-left: 5px;
  margin-bottom: 1.5rem;
}
.boxTitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

.boxAmount {
  font-weight: 900;
}

.innerSquare {
  width: 12px;
  height: 12px;
  background: #000000;
  margin: 2px;
}

.availableBalanceContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
}
.availableBalanceText {
  font-size: 1rem;
  color: #a0a0a0;
}
.availableBalanceBox {
  display: flex;
}
.availableBalance {
  font-size: 18px;
  font-style: normal;
  font-weight: 900;

  letter-spacing: 0em;
  text-align: right;
}
.totalBalance {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-top: 6px;
  letter-spacing: 0em;
  text-align: right;
}
.footer {
  margin: 0 1rem 0.5rem 0;
  display: flex;
  justify-content: flex-end;
}
.textWarning {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding-top: 10px;
  color: #a0a0a0;
}
.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}

.saveButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.noDataDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 5.25rem;
}
.noData {
  font-size: 1rem;
  margin-top: 1.875rem;
  line-height: 19px;
}

.downArrow__BudgetDialog {
  display: none;
}
.dropDownHeaderCon__BudgetDialog {
  display: none;
}
.BudgetDialog {
  overflow-y: hidden;
  background-color: #fff;
}
.displayDropDown {
  display: block;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .eachCalculationDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;
  }
  .calculationDiv {
    border-radius: 4px;
    border: 1px solid #ffbe0e;
    background: #fffef5;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    gap: 6px;
  }
  .monthlySpendDivWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 1.5rem;
  }
  .boxInputContainer {
    p:nth-child(1) {
      display: none !important;
    }
  }

  .exampleStoreDiv {
    display: flex;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #dedede;
    background: #fff;
    box-shadow: 0px 5px 12px 0px rgba(151, 116, 20, 0.08);
    padding: 12px;
    flex-direction: column;
  }
  .shirtDiv {
    background-color: #f8f5ed;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .shirtDescriptionDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .BudgetDialog {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .peerFivesAndBudgetCont {
    display: flex;
    flex-direction: column;
  }
  .logoIcon {
    width: 40px;
    height: 40px;
  }
  .headerContainer {
    border-bottom: 1px solid #ececec;
  }
  .divider {
    display: none;
  }

  .closeIcon {
    top: 30px;
    right: 25px;
  }
  .dropDownHeaderCon__BudgetDialog {
    display: flex;
    justify-content: space-between;
  }

  .availableBalanceContainer {
    padding-bottom: 0px;
  }
  .dropDownBodyCont__BudgetDialog {
    margin-top: 10px;
  }
  .peerFivesAndBudgetCont {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
  }
  .gridContainer {
    border: 1px solid #d7d7d7;
    padding: 1rem 1.5rem 1rem 1.5rem;
    margin: 1.5rem 1.5rem 0rem 1.5rem;
    background-color: #fcfcfc;
    border-radius: 4px;
    max-height: none;
  }
  .downArrow__BudgetDialog {
    display: block;
    cursor: pointer;
  }

  .displayDropDown {
    display: flex;
    flex-direction: column;
    height: 50vh;
  }
  .closeDropDown {
    display: none;
  }
}
