.StripeForm {
  display: contents;
  color: #a0a0a0;
}
.BillingForm {
  display: contents;
  color: #a0a0a0;
}
.StripeFormFields {
  display: flex;
}
.StripeFormFields label {
  width: 273px;
}

.StripeForm label {
  font-weight: 500;
  font-size: 16px;
  border: 1px #d7d7d7;
  margin-bottom: 0px;
}
.BillingForm label {
  font-weight: 500;
  font-size: 12px;
  border: 1px #d7d7d7;
  margin-bottom: 20px;
}
.InlineLabel {
  display: grid;
}
.formControlSelect {
  margin-right: 32px;
  display: contents;
}
.selectCountry {
  width: 100%;
  height: 43px;
  color: #a0a0a0;
  font-size: 16px;
}
.StripeElement {
  display: block;
  max-width: 35.625rem;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background: white;
  margin: 0 0 20px 0;
}
input::placeholder {
  color: #a0a0a0;
}
.StripeFormFields input,
.InlineLabel input {
  width: 100%;
  display: block;
  max-width: 35.625rem;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background: white;
  height: 43px;
}
.contentBox {
  padding: 20px 23px 30px 23px;
  text-align: center;
}
.CardDetailsTitle {
  font-weight: 900;
  font-size: 18px;
  padding: 20px 0px;
}
.Logo {
  padding-left: 12px;
  align-self: center;
}
.logoChecked {
  align-self: center;
}
.footer {
  margin-top: 1.875rem;
  display: flex;
  justify-content: flex-end;

  .loader {
    margin: unset;
  }
}
.skipButton {
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
  padding: 12px 20px;
  margin-right: 10px;
  text-transform: capitalize;
}

.saveButton {
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  background-color: #000000;
  padding: 12px 20px;
  text-transform: capitalize;
}
// .StripeFormFields input:focus,
// .StripeElement--focus {
//   box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
//     rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
//   -webkit-transition: all 150ms ease;
//   transition: all 150ms ease;
// }

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
.errorMessage {
  justify-content: center;
  display: flex;
  color: #e93a3a;
  font-size: 12px;
}
.TrialPeriodNote {
  width: fit-content;
  display: flex;
  padding: 5px;
  background: #fffadf;
  font-size: 12px;
  border: 1px solid #fff2af;
  margin-bottom: 1.2rem;
  align-items: center;
  border-radius: 4px;
}
.warningLogo {
  margin-right: 5px;
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
  .CardDetailsTitle {
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
  }
  .Logo {
    padding-left: 29px;
    align-self: center;
    width: 106px;
    height: 24px;
  }
  .TrialPeriodNote {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 10px;
  }
  .StripeFormFields {
    flex-direction: column;
  }
  .InlineLabel label {
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  .StripeElement {
    margin: 0 0 24px 0;
  }
  .StripeFormFields label {
    width: 100%;
  }
  .footer {
    justify-content: center;
    flex-wrap: wrap;
  }
  .skipButton {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
  }
  .saveButton {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
  }
}
